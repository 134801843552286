@tailwind base;
@tailwind components;
@tailwind utilities;

/* dropdown */
.dropdown {
  @apply relative;
}

.dropdown > button {
  @apply flex;
}

.dropdown ul {
  @apply my-1 min-w-[120px] rounded bg-white p-0 py-2 shadow dark:bg-[#1b2e4b] text-black dark:text-white;
}

.dropdown ul li > a,
.dropdown ul li > button {
  @apply flex items-center px-4 py-2 hover:bg-[#FAFAFA] hover:text-primary-text;
}

.dropdown ul li > button {
  @apply w-full;
}

.table-cell {
  word-wrap: break-word;
  white-space: normal;
  max-width: 150px;
  /* Adjust this value as needed */
}

.ant-table-thead > tr > th {
  font-weight: bold !important;
}

.custom-input,
.custom-input input {
  outline: 0;
  outline-offset: 0;
  border-color: black;
  background-color: rgba(255, 255, 255, 0.5);
  /* #FFFFFF80 */
}

/* Styles for custom input on focus, active, and hover states */
.custom-input:focus,
.custom-input:active,
.custom-input:hover,
.custom-input input:focus,
.custom-input input:active,
.custom-input input:hover {
  outline: 0;
  outline-offset: 0;
  border-color: black;
  background-color: rgba(255, 255, 255, 0.5);
  /* #FFFFFF80 */
}

/* Change the placeholder text color */
.custom-input::placeholder,
.custom-input input::placeholder {
  color: #010101;
  /* Change this to your desired color */
  opacity: 1;
  /* Override default opacity */
}

/* Unchecked state */
.custom-checkbox .ant-checkbox-inner {
  background-color: #f4de87 !important;
  /* Tailwind yellow-400 color */
  border-color: #000000 !important;
}

/* Checked state */
.custom-checkbox .ant-checkbox-checked .ant-checkbox-inner {
  background-color: black !important;
  border-color: black !important;
}

/* Checked state - fixing the after pseudo-element color */
.custom-checkbox .ant-checkbox-checked::after {
  border-color: black !important;
  background-color: black !important;
  /* Ensure the pseudo-element stays black */
}

/* Hover state for unchecked checkbox */
.custom-checkbox .ant-checkbox:hover .ant-checkbox-inner,
.custom-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #facc15 !important;
  background-color: #facc15 !important;
}

/* Hover state for checked checkbox */
.custom-checkbox .ant-checkbox-checked:hover .ant-checkbox-inner,
.custom-checkbox .ant-checkbox-checked-input:focus + .ant-checkbox-inner {
  border-color: black !important;
  background-color: black !important;
}

.login-button {
  background-color: #000 !important;
  color: #facc15 !important;
}

.login-button:hover {
  background-color: #000 !important;
  color: #facc15 !important;
}
.login-button:active {
  background-color: #000 !important;
  color: #facc15 !important;
}


.save-button {
  background: #E8BC0E !important;
  color: #010101 !important;
}

.cancel-button {
  background: #fff !important;
  color: #f5222d !important;
  border: 1px solid #f5222d !important;
  margin-right: 10px;
}

.action-menu {
  color: #000000 !important;
  background: none !important;
  font-size: 30px;
  box-shadow: none;
}

.table-cell {
  font-size: 18px;
  font-family: "Roboto-Regular";
}

.custom-upload-wrapper .ant-upload.ant-upload-select {
  width: 550px !important;
  height: 210px !important;
}

.custom-upload-wrapper .ant-upload.ant-upload-select-picture-card {
  width: 100% !important;
  height: 100% !important;
}

.phone-number-input {
  display: flex;
  align-items: center;
}

.country-code-select {
  width: 120px; /* Adjust width to fit the flag and code comfortably */
}

.phone-input {
  flex: 1;
  margin-left: 10px;
}

.country-option {
  display: flex;
  align-items: center;
}

.country-flag {
  margin-right: 8px;
}

.country-code {
  margin-left: 5px;
}

.ant-form-item-required {
  font-family: "Roboto-Medium";
  font-size: 17px !important;
}

.ant-menu-item-selected {
  font-size: 24px !important;
  font-family: "Roboto-Bold";
}



/* ================= Delete Confirm modal css start ============= */

.custom-confirm-modal .ant-modal-body {
  padding: 16px;
}

.custom-confirm-modal .ant-modal-confirm-body {
  display: flex;
  align-items: center;
}

.custom-confirm-modal .ant-modal-confirm-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 8px;
}

.custom-confirm-modal .ant-modal-confirm-content {
  font-size: 14px;
  color: #888;
}

.custom-confirm-modal .ant-modal-confirm-btns {
  display: flex;
  justify-content: end;
}

.custom-confirm-modal .ant-btn-default {
  border: 1px solid #F5222D;
  color: #F5222D;
  background-color: #fff;
  font-family: "Roboto-Bold";
}

.custom-confirm-modal .ant-btn-dangerous {
  background-color: #E8BC0E;
  border-color: #ffbf00;
  color: #010101;
  font-family: "Roboto-Bold";
}


/* ================= Delete Confirm modal css end ============= */




.home-add-button.ant-btn:active {
  color: #010101;
  border-color: transparent !important;
  background: #E8BC0E !important;
}

 .home-add-button.ant-btn:hover {
  color: #010101;
  border-color: transparent !important;
  background: #E8BC0E !important;
}

.custom-spinner .ant-spin-dot-item {
  background-color: #E8BC0E !important; /* Change this to your desired color */
}

